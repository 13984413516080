import React, { useState, useEffect } from 'react';
import "react-tooltip/dist/react-tooltip.css";
import axios from 'axios';
import ApexCharts from 'apexcharts'
import ReactApexChart from 'react-apexcharts';
import { Tooltip as ReactTooltip } from "react-tooltip";
import useWindowDimensions from './hooks/windowDimensions';

import './App.css'
import loadingGif from './assets/loading.gif'

const inputRegex = new RegExp('^[a-zA-Z]*$')


const site_map = {
    'thestreet': 'thestreet.com',
    'zacks': 'zacks.com',
    'gurufocus': 'gurufocus.com',
    'stocktwits': 'stocktwits.com'
}

const StockSentimentChart = () => {
    const [ratingChangeData, setRatingChangeData] = useState({});
    const [hasError, setHasError] = useState(false)
    const [series, setSeries] = useState([])
    const [symbol, setSymbol] = useState(null)
    const [companyName, setCompanyName] = useState(symbol)
    const [zacks, setZacks] = useState('N/A')
    const [thestreet, setTheStreet] = useState('N/A')
    const [gurufocus, setGuruFocus] = useState('N/A')
    const [stocktwits, setStockTwits] = useState('N/A')
    const [symbolInput, setSymbolInput] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [selection, setSelection] = useState('one_month')
    const [yMax, setYMax] = useState(120)
    const [displayChart, setDisplayChart] = useState(false)
    const [unalteredRatings, setUnalteredRatings] = useState({})
    const [scaledSentiment, setScaledSentiment] = useState(true)

    const { height, width } = useWindowDimensions()


    const [options, setOptions] = useState({
        chart: {
            id: 'stocks',
            type: 'line',
            height: height,


        },
        dataLabels: {
            enabled: false
        },
        colors: ['#ae1adb', '#1a1edb', '#1adbc2', '#525252'],
        markers: {
            size: 0,
            style: 'hollow',
        },
        xaxis: {
            type: 'datetime',

            tickAmount: 6,
        },

        yaxis: {
            min: 1,
        },

        annotations: {
            position: 'back',
            yaxis: [{
                y: 100,
                borderWidth: 2,
                borderColor: '#00c717',
                label: {
                    show: true,
                    text: 'Strong Buy',
                    style: {
                        color: "#000",
                        background: '#00c717'
                    }
                },
            },
            {
                y: 50,
                borderColor: '#a6c700',
                borderWidth: 2,
                label: {
                    show: true,
                    text: 'Hold',
                    style: {
                        color: "#000",
                        background: '#a6c700'
                    }
                },


            },
            {
                y: 1,
                borderColor: '#c70000',
                borderWidth: 2,
                label: {
                    show: true,
                    text: 'Strong Sell',
                    style: {
                        color: "#000",
                        background: '#c70000'
                    }
                },


            }
                ,


            ]
        },
        selection: false

    })

    useEffect(() => {
        if (series.length > 9) {
            console.log(series)
            try {
                ApexCharts.exec('stocks', 'updateOptions',
                    {
                        options: options,
                    })

                ApexCharts.exec('stocks', 'updateSeries', [series])

            }
            catch (err) {
                console.log(err)
            }
        }

    }, [options])

    const renderChart = () => {
        let chartHeight = parseInt(height * .7)
        if (chartHeight < 300) chartHeight = 300

        return (


            <div id="chart-timeline">
                <ReactApexChart options={options} series={series} type="line" height={chartHeight} />
            </div>

        )

    }


    const chart = renderChart()
    const loadingGifElement = <img style={{ height: '2em' }} src={loadingGif} alt="loading" />

    useEffect(() => {
        try {



            const fetchData = async () => {
                try {

                    setHasError(false)
                    setYMax(120)

                    if (!symbol) return
                    const searchSymbol = symbol
                    setSeries([])

                    setIsLoading(true)
                    const ratingsChanges = await axios.get(`https://api.cornblaster.com/stockdata/rating-changes/${symbol}`)
                    setRatingChangeData(ratingsChanges.data)
                    const newSeries = []
                    setDisplayChart(true)

                    Object.keys(ratingsChanges.data).forEach((site) => {

                        let seriesName
                        let seriesType = 'line'

                        if (site_map?.[site]) {
                            seriesName = site_map?.[site] || null
                        }
                        else {
                            seriesName = `${symbol} Stock Price`
                            seriesType = 'area'
                            ratingsChanges.data[site].forEach((val) => {
                                if (val[1] > yMax) {

                                }

                            })
                        }



                        const serie = {
                            name: seriesName,
                            data: ratingsChanges.data[site],
                            type: seriesType
                        }

                        setSeries(oldArray => [...oldArray, serie])

                    })


                    const zacksRating = await axios.get(`https://api.cornblaster.com/stockdata/rating/zacks/${symbol}`)
                    const streetRating = await axios.get(`https://api.cornblaster.com/stockdata/rating/thestreet/${symbol}`)
                    const gurufocusRating = await axios.get(`https://api.cornblaster.com/stockdata/rating/gurufocus/${symbol}`)
                    const stocktwitsRating = await axios.get(`https://api.cornblaster.com/stockdata/rating/stocktwits/${symbol}`)
                    setIsLoading(false)
                    setZacks("zacks.com: " + zacksRating.data.quant)
                    setTheStreet("thestreet.com: " + streetRating.data.quant)
                    setGuruFocus("gurufocus.com: " + gurufocusRating.data.quant)
                    setStockTwits("stocktwits.com: " + stocktwitsRating.data.quant)
                    const unalteredRatingsResp = await axios.get(`https://api.cornblaster.com/stockdata/unaltered-ratings/${symbol}`)
                    setUnalteredRatings(unalteredRatingsResp.data)
                    setCompanyName(`${unalteredRatingsResp.data?.name} (${symbol})`)


                } catch (err) {
                    setHasError(true)
                }
            };

            fetchData();
        }
        catch (err) {
            setHasError(true)


        }
    }, [symbol]);


    const renderFormInput = () => {
        return (
            <form onSubmit={(e) => {
                e.preventDefault()
                setSymbol(symbolInput.toUpperCase())


            }}>
                <label htmlFor="symbolInput">Symbol:
                    <input
                        style={{ marginLeft: '0.5em', width: '5em' }}
                        id="symbolInput"
                        value={symbolInput}
                        placeholder="e.g. AAPL"
                        onChange={(e) => {

                            if (inputRegex.test(e.target.value)) {
                                setSymbolInput(e.target.value)
                            }

                        }

                        }
                        onSubmit={() => {

                            console.log("submit")

                            setSymbol(symbolInput)

                        }} />

                </label>
                <button style={{ marginLeft: '0.5em' }} type="submit">Submit</button>
            </form>
        )

    }


    return (
        <div style={{ marginLeft: `1em`, marginRight: '1em', }}>


            <div>
                {!hasError && !symbol && <div><h2>Stock Sentiment Analysis</h2><p>Enter a NASDAQ or NYSE ticker symbol below (e.g. MSFT or AAPL)</p>{renderFormInput()}</div>}
                {hasError && <h3 style={{ color: 'red' }}>An error occurred.  Please try a different ticker symbol.</h3>}

            </div>

            {symbol &&
                <div style={{ marginBottom: '2em' }}>
                    {!hasError && <h2>Stock Sentiment Analysis for {companyName || symbol} </h2>}
                    {renderFormInput()}
                    {!hasError &&
                        <div>
                            {scaledSentiment && <h3>Current Sentiment Scaled 1 to 100</h3>}
                            {!scaledSentiment && <h3>Current Sentiment Original Ratings</h3>}
                            
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {isLoading ? <div className='rating'>zacks.com: {loadingGifElement}</div>
                                    : <div id="zacks_rating" className='rating'>{scaledSentiment ? zacks : unalteredRatings['zacks_rating']}</div>
                                }
                                {isLoading ? <div className='rating'>thestreet.com: {loadingGifElement}</div>
                                    : <div id="thestreet_rating" className='rating'>{scaledSentiment ? thestreet : unalteredRatings['thestreet_rating']}</div>
                                }
                                {isLoading ? <div className='rating'>gurufocus.com: {loadingGifElement}</div>
                                    : <div id="gurufocus_rating" className='rating'>{scaledSentiment ? gurufocus : unalteredRatings['gurufocus_rating']}</div>
                                }
                                {isLoading ? <div className='rating'>stocktwits.com: {loadingGifElement}</div>
                                    : <div id="stocktwits_rating" className='rating'>{scaledSentiment ? stocktwits : unalteredRatings['stocktwits_rating']}</div>
                                }

                            </div>
                            <div className="rating">
                            {scaledSentiment && <button style={{marginTop:"1em"}} href="#" onClick={() => setScaledSentiment(!scaledSentiment)}>See original ratings</button>}
                            {!scaledSentiment && <button style={{marginTop:"1em"}} href="#" onClick={() => setScaledSentiment(!scaledSentiment)}>Change to scaled ratings</button>}
                            </div>
                        </div>}
                    {unalteredRatings?.zacks_rating &&
                        <ReactTooltip
                            anchorId="zacks_rating"
                            place="top"
                            content={unalteredRatings['zacks_rating']}
                            clickable
                        />
                    }
                    {unalteredRatings?.thestreet_rating && 
                    <ReactTooltip
                        anchorId="thestreet_rating"
                        place="top"
                        content={unalteredRatings['thestreet_rating']}
                        clickable
                    />
                    }
                    { unalteredRatings?.gurufocus_rating &&
                    <ReactTooltip
                        anchorId="gurufocus_rating"
                        place="top"
                        content={unalteredRatings['gurufocus_rating']}
                        clickable
                    />
                    }

                </div>

            }
            <div id="chart-root">
                {series.length > 0 && renderChart()}
            </div>
        </div>
    );
};

export default StockSentimentChart;